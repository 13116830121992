import { render, staticRenderFns } from "./AddComment.vue?vue&type=template&id=1dba25e7&scoped=true"
import script from "./AddComment.vue?vue&type=script&lang=ts"
export * from "./AddComment.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1dba25e7",
  null
  
)

export default component.exports