
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { Sermon } from '~/models/sermon'
import { User } from '~/models/users/user'
import {
  ToastApiError,
  ToastError,
  ToastSuccess,
} from '~/assets/ts/utils/toast'
import { AlertButton } from '~/models/generic/alert-button'
import { siteLoginUrl, siteUserPageUrl } from '~/assets/ts/utils/urls'
import { SiteAlertModal } from '~/assets/ts/utils/site'
import SiteUserProfileIcon from '~/components/site/user/ProfileIcon.vue'
import SiteButtonSpinner from '~/components/site/ButtonSpinner.vue'
import SaLink from '~/components/_general/SaLink.vue'

export default Vue.extend({
  name: 'SiteUserAddComment',
  components: { SaLink, SiteButtonSpinner, SiteUserProfileIcon },
  props: {
    sermon: {
      type: Object as PropType<Sermon>,
      default: undefined,
    },
  },
  data() {
    return {
      waiting: false,
      submitted: false,
      commentTitle: '',
      commentText: '',
      minTextLength: 5,
      minTitleLength: 2,
    }
  },
  computed: {
    signInUrl(): string {
      return siteLoginUrl(this)
    },
    signUpUrl(): string {
      return siteLoginUrl(this, true)
    },
    learnMoreUrl(): string {
      return siteUserPageUrl('about')
    },
    buttonText(): TranslateResult {
      if (this.submitted) return this.$t('Submitted!')
      return this.$t('Submit Comment')
    },
    disabled(): boolean {
      return this.waiting || this.submitted
    },
    user(): User | undefined {
      return this.$users.user
    },
    commentPlaceholder(): TranslateResult {
      return this.$t('Write a comment...')
    },
    titlePlaceholder(): TranslateResult {
      const example = `"${this.$t('Great Sermon!')}"`
      return this.$t('Title (e.g. {example})', { example })
    },
  },
  methods: {
    submit() {
      if (!this.sermon) return
      if (!this.commentText) {
        ToastError(
          this.$t('Comment must contain at least {n} characters.', {
            n: this.minTextLength,
          })
        )
        return
      }
      SiteAlertModal(this, {
        title: this.$t('Ready to submit your comment?'),
        text: `**${this.commentTitle}**\n${this.commentText}`,
        neutral: new AlertButton({
          text: this.$t('Not yet'),
        }),
        positive: new AlertButton({
          text: this.$t('Submit'),
          event: async () => {
            this.waiting = true
            await this.$apiClient
              .createComment(this.sermon.id, {
                title: this.commentTitle,
                body_text: this.commentText,
              })
              .then(() => {
                this.submitted = true
                ToastSuccess(this.$t('Comment submitted!'))
              })
              .catch((reason) => {
                ToastApiError(reason, this)
              })
            this.waiting = false
          },
        }),
      })
    },
  },
})
